import React from "react"
// import * as styles from "pages/member/signUp/single.module.css"
import Title from "components/tools/InputTitle.js"
const Input = props => {
  const style = props.style ? { ...props.style } : null

  return (
    <div>
      <Title style={style} name={props.title} titleSize={props.titleSize} />
      <div className={"inputContainer"}>
        {props.btn ? (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              gap: "10px",
              paddingLeft: 0,
            }}
          >
            <input
              type="text"
              name={props.tagname}
              placeholder={props.placeholder}
              defaultValue={props.value}
              onChange={props.onChange}
              style={props.inputStyle}
            />
            {props.btn}
          </div>
        ) : !props.read ? (
          <input
            type="text"
            name={props.tagname}
            placeholder={props.placeholder}
            defaultValue={props.value}
            onChange={props.onChange}
            style={props.inputStyle}
          />
        ) : (
          <input
            type="text"
            name={props.tagname}
            defaultValue={props.value}
            style={props.inputStyle}
            readOnly
          />
        )}

        {props.left}
        <div>
          <div
            className="errMsg"
            name={props.tagname}
            style={{ fontWeight: "500" }}
          ></div>
          {props.right}
        </div>
      </div>
    </div>
  )
}

export default Input
