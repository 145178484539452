// id 중복체크
import React from "react"
import _ from "lodash"
import json from "contents/memberUtils.json"
import { textBox, textBoxErr } from "pages/member/signUp/group.module.css"
import BottomBtnModal from "components/tools/modalComponents/bottomBtnModal.js"
import api from "apis"
import { phone_uuid } from "utils/mobileUtils.js"
import { navigate } from "gatsby-link"
import OneBtnModal from "components/tools/modalComponents/oneBtnModal"
import { mobileConfirm } from "utils/mobileUtils.js"
import find from "utils/findUtils.js"
import { page, getFormData } from "utils"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
export const idCheck = async () => {
  const [idTag, msgTag] = document.getElementsByName("id")

  if (!idTag || !msgTag || !idTag.value) {
    msgTag.style.color = "#E95C63"
    msgTag.textContent = json.idNullErrMsg
    vaild.id = false
    return
  }

  if (!idReg(idTag.value)) {
    msgTag.style.color = "#E95C63"
    msgTag.textContent = json.idShortErrMsg
    vaild.id = false
    return
  }
  const check = await api.idOverlap(idTag.value)

  if (check) {
    //중복일시
    msgTag.style.color = "#E95C63"
    msgTag.textContent = json.idErrMsg
    vaild.id = false
  } else {
    //사용가능할시
    msgTag.style.color = "#0b4247"
    msgTag.textContent = json.idSucMsg
    vaild.id = true
  }
}

//비밀번호

export const onPwdCheck = () => {
  const [mainInput, mainError, pwdInput, pwdError] =
    document.getElementsByName("pwd")
  const [tag] = document.getElementsByName("tag")
  if (!(mainInput || mainError || pwdInput || pwdError || tag)) {
    vaild.pwdMain = false
    vaild.pwdSub = false
  }

  // 메인입력
  if (pwdReg(mainInput.value)) {
    mainError.textContent = ""
    tag.style.top = "65px"
    vaild.pwdMain = true
  } else {
    mainError.textContent = json.pwdRegErrMsg
    tag.style.top = "85px"
    vaild.pwdMain = false
  }
  //서브

  if (pwdInput.value !== "" && mainInput.value === pwdInput.value) {
    pwdError.textContent = ""
    tag.style.color = `#0b4247`
    tag.style.display = "block"
    vaild.pwdSub = true
  } else {
    pwdError.textContent = json.pwdSameErrMsg
    tag.style.color = `#757575`
    tag.style.display = "none"
    vaild.pwdSub = false
  }
}

export const pwdMainCheckUtil = ({ target: { value } }, setActive) => {
  const [, pwdMsgTag] = document.getElementsByName("pwd")
  if (!pwdMsgTag) return
  console.log(value)
  console.log(pwdReg(value))

  if (pwdReg(value)) {
    pwdMsgTag.textContent = ""
    setActive(true)
    vaild.pwdMain = true
  } else {
    pwdMsgTag.textContent = json.pwdRegErrMsg
    setActive(false)
    vaild.pwdMain = false
  }
}

export const pwdSubCheckUtil = ({ target: { value } }, setActive) => {
  const [pwdMainTag] = document.getElementsByName("pwd")
  const [, , , pwdMsgTag] = document.getElementsByName("pwd")
  const [tag] = document.getElementsByName("tag")

  if (!pwdMainTag || !pwdMsgTag || !tag) return

  if (pwdMainTag.value === value) {
    pwdMsgTag.textContent = ""
    tag.style.color = `#0b4247`
    tag.style.display = "block"
    setActive(true)

    vaild.pwdSub = true
  } else {
    pwdMsgTag.textContent = json.pwdSameErrMsg
    tag.style.color = `#757575`
    tag.style.display = "none"
    setActive(false)

    vaild.pwdSub = false
  }
}

//이름

export const nameCheckUtil = ({ target: { value } }) => {
  const [, nameErrTag] = document.getElementsByName("name")

  console.log(nameErrTag)
  if (!nameErrTag) return

  if (nameReg(value)) {
    nameErrTag.textContent = ""
    vaild.name = true
  } else {
    nameErrTag.textContent = json.nameErrMsg
    vaild.name = false
  }
}
//이름

export const emailCheckUtil = value => {
  const [, emailRegTag] = document.getElementsByName("email")
  if (!emailRegTag) return
  console.log(142142, value)

  if (emailReg(value)) {
    emailRegTag.textContent = ""
    vaild.email = true
  } else {
    emailRegTag.textContent = json.emailErrMsg
    vaild.email = false
  }
}

//가입
export const join = _.debounce(async (setmodalOpen, setModalMain) => {
  const [id, pwd, name, mobile, email] =
    document.getElementsByClassName("inputContainer")
  const [emailValue] = document.getElementsByName("email")
  blankCheck([id, pwd, name, mobile, email])

  if (!vaild.id) {
    alert("아이디 중복확인을 체크해주세요")
    return
  }

  onPwdCheck()
  emailCheckUtil(emailValue.value)
  if (!vaild.name) {
    alert("이름을 확인해주세요")
    return
  } else if (!phone_uuid) {
    // alert("휴대전화 인증을 다시주세요")
    return
  } else if (stop || id.querySelector(".errMsg").textContent === "") {
    alert("입력한 정보를 다시 확인해주세요.")
    return
  } else if (!vaild.pwdMain || !vaild.pwdSub) {
    alert("비밀번호를 확인해주세요")
    return
  } else if (!vaild.email) {
    alert("이메일을 확인해 주세요.")
    return
  }

  const result = await api.signUp({
    body: {
      account_id: id.querySelector("input").value,
      account_name: name.querySelector("input").value,
      account_password: pwd.querySelector("input").value,
      phone_uuid,
      account_email: email.querySelector("input").value,
    },
  })

  if (result) {
    setmodalOpen(true)
    setModalMain(
      <OneBtnModal
        close={() => {
          setmodalOpen(false)
        }}
        size={{
          w: 430,
          h: 190,
        }}
        confirm="확인"
      >
        <div
          style={{
            fontSize: "30px",
            textAlign: "center",
            color: "black",
          }}
        >
          잠시후 다시 시도해주세요.
        </div>
      </OneBtnModal>
    )
  } else {
    // 회원가입 성공페이지

    await login(
      id.querySelector("input").value,
      pwd.querySelector("input").value,
      name.querySelector("input").value
    )
  }
}, 2000)

//로그인
// const { dialog } = require("electron")
export const login = async (acc_id = null, acc_pwd = null, name = null) => {
  console.log(111)
  const [idInput, pwdInput] = document.getElementsByName("loginInput")

  const isAcc = acc_id !== null && acc_pwd !== null

  const account_id = isAcc ? acc_id : idInput?.value
  const account_password = isAcc ? acc_pwd : pwdInput?.value

  const account_finger = await getFinger()

  const [profile, desc] = await api.login({
    body: {
      account_id,
      account_password,
      account_finger,
    },
  })
  console.log(":t")
  console.log(desc)
  if (desc === "success") {
    console.log("test", name)
    if (name) {
      navigate("/member/success/signUp", {
        state: {
          name,
        },
      })
    } else {
      const path =
        profile === null ? `/member/profile/home` : "/member/success/login"
      navigate(path)
    }
  } else if (desc === "invalid finger") {
    console.log("test")
    navigate(`/member/profile/home`, {
      state: {
        isCurrentUser: desc,
        account_id,
        account_password,
        account_finger,
      },
    })
  } else if (desc === "invalid password") {
    alert("비밀번호를 확인해주세요.", "#000")
  } else if (desc === "account not found") {
    alert("아이디를 확인해주세요.", "#000")
  } else {
    alert("로그인 실패", "#000")
  }
}
// 멤버 아이디, 비밀번호 찾기

export const onFind = _.debounce(
  async (setMain, setBtnName, btnName, property) => {
    if (btnName === "로그인") navigate("/login")
    if (btnName === "저장하기") property += "save"

    blankCheck(document.getElementsByClassName("inputContainer"))

    if (stop) return

    if (property.endsWith("mobile")) {
      await mobileConfirm()
      if (!phone_uuid) return
    }

    await find[property](setMain, setBtnName, phone_uuid)
  },
  500
)
//단체회원 문의
export const onQuestion = _.debounce(
  async (setmodalOpen, setModalMain, fileList) => {
    stop = false

    const [name, ins, phone, email] =
      document.getElementsByClassName("inputContainer")

    blankCheck([name, ins, phone, email])
    console.log(stop)
    if (stop) {
      alert("필수 입력 내용을 기입해주세요")
      return
    }

    // //textarea 공백체크
    const [{ value, classList }] = document.getElementsByName("introduce")
    textareaCheck(value, classList)

    if (stop) {
      alert("필수 입력 내용을 기입해주세요")
      return
    }

    checkboxCheck("termcheck")

    if (stop) {
      alert("동의를 체크해주세요.")
      return
    }

    //파일 체크

    if (stop) return

    const formdata = getFormData({
      join_name: name.querySelector("input").value,
      join_organization: ins.querySelector("input").value,
      join_phone: phone.querySelector("input").value,
      join_email: email.querySelector("input").value,
      join_memo: value,
    })

    fileList.forEach(file => formdata.append("data_attach", file))

    const result = await api.groupJoin({ body: formdata })

    if (result) {
      setmodalOpen(true)
      setModalMain(
        <BottomBtnModal
          close={() => {
            setmodalOpen(false)
            navigate("/login")
          }}
          size={{
            w: 410,
            h: 217,
          }}
          title={"등록 되었습니다."}
        >
          <div style={{ marginTop: "30px" }}>
            문의하신 내용을 검토 후 휴대전화 또는 이메일로 답변드리겠습니다.
          </div>
        </BottomBtnModal>
      )
    } else {
      alert("잠시 후 다시 시도 해주세요")
    }
  },
  500
)

// 전역 변수,함수
let stop = false
export let vaild = {
  id: false,
  pwdMain: false,
  pwdSub: false,
  name: false,
  email: false,
}

const setStop = params => {
  if (typeof params == "boolean") stop = params
}

//아이디 정규식
export const idReg = id => {
  // return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/.test(id)
  return /^(?=.*?[a-z])([a-zA-Z0-9_-]){6,}$/.test(id)
}

// 비밀번호 정규식
export const pwdReg = pwd => {
  return /^(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,16}$/g.test(pwd)
}

export const numReg = email => {
  return /^[0-9]+$/g.test(email)
}

// 이름 정규식
const nameReg = name => {
  return name.search(/\s/) === -1 && !/[~!@#$%^&*()_+|<>?:{}]/g.test(name)
}

//이메일 정규식
export const emailReg = email => {
  return /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/g.test(
    email
  )
}

//각 input의 공백 확인 후 에러메세지 표시
const blankCheck = inputs => {
  console.log(11)
  Array.from(inputs).forEach(inputContainer => {
    for (let i = 0; i < inputContainer.querySelectorAll("input").length; i++) {
      const errMsg =
        inputContainer.querySelectorAll(".errMsg")[i] ??
        inputContainer.querySelectorAll(".errMsg")[0]
      // if (inputContainer.querySelectorAll("input")[i].value.length === 0) {
      if (!inputContainer.querySelectorAll("input")[i].value) {
        errMsg.textContent = json.blankErrMsg
        setStop(true)
      } else {
        if (errMsg.textContent === json.blankErrMsg) errMsg.textContent = ""
        setStop(false)
      }
    }
  })
}

export const textareaCheck = (value, classList) => {
  if (value) {
    classList.replace(textBoxErr, textBox)
    setStop(false)
  } else {
    classList.replace(textBox, textBoxErr)
    setStop(true)
  }
}

const checkboxCheck = name => {
  const [{ checked }, errMsg] = document.getElementsByName(name)
  if (checked) {
    errMsg.textContent = ""
    setStop(false)
  } else {
    errMsg.textContent = json.checkErrMsg
    setStop(true)
  }
}

const getFinger = async () => {
  const fpPromise = FingerprintJS.load()
  const fp = await fpPromise
  const userFp = await fp.get()
  return userFp.visitorId
}

const vaildType = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms",
  "application/vnd.openxmlformats",
  "image/jpeg",
  "image/png",
]
