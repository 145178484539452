import React from "react"
import * as styles from "./oneBtnModal.module.css"

const OneBtnModal = props => {
  const containerStyle = {
    width: `${props.size.w}px`,
    height: `${props.size.h}px`,
  }

  return (
    <div className={styles.main} style={containerStyle}>
      <main>{props.children}</main>
      <footer onClick={props.close}>
        <div>
          <div
            className={`confirmBtn ${props.className}`}
            style={{ width: "268px" }}
          >
            {props.confirm}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default OneBtnModal
