import api from "apis"
import React from "react"
// const aligoapi = require('aligoapi');

// import aligoapi from 'aligoapi';

//모바일 인증번호 받기
export const getMobileConfirm = async (setErrMsg = null, setTime = null) => {
  const flag = setErrMsg !== null && setTime !== null

  const [phoneNum, , err] = document.getElementsByName("mobile")
  if (!phoneNum?.value) {
    err.textContent = "전화번호를 입력해 주세요."
    return
  } else {
    err.textContent = ""
  }

  const result = await api.getMobileConfirm({
    body: { phone_number: phoneNum.value },
  })

  let msg = null
  let time = ""
  if (result?.uuid) {
    phone_uuid = result.uuid
    alert("입력한 휴대폰 번호로 인증번호가 전송되었습니다.")

    if (flag) {
      time = 180
      msg = (
        <div style={{ fontSize: "10px", color: "#7C7C7C" }}>
          인증번호를 입력하신 휴대폰 번호로 발송했습니다.
          <br /> 인증번호가 오지 않으면 입력하신 번호가 정확한지 확인해주세요.
          <br />
          이미 가입된 번호, 가상전화번호는 인증번호를 받을 수 없습니다.
        </div>
      )
    }
  } else {
    if (flag) {
      time = ""
      msg = (
        <div style={{ fontSize: "12px", color: "#e95c63" }}>
          잘못된 핸드폰번호 입니다.
        </div>
      )
    } else {
      err.textContent = "잘못된 핸드폰 번호입니다."
    }
  }
  if (flag) {
    setTime(time)
    setErrMsg(msg)
  }
}

//모바일 인증하기
export const mobileConfirm = async (setErrMsg = null, setTime = null) => {
  const flag = setErrMsg !== null && setTime !== null
  const [, phoneNum, err] = document.getElementsByName("mobile")

  if (!phoneNum?.value) {
    const errorMsg = "전화번호를 입력해주세요."

    if (flag) {
      setErrMsg(
        <div style={{ fontSize: "12px", color: "#e95c63" }}>{errorMsg}</div>
      )
    } else {
      err.textContent = errorMsg
    }
    return
  }

  const result = await api.mobileConfirm({
    body: {
      phone_uuid,
      phone_code: phoneNum?.value,
    },
  })

  if (flag) {
    const errorMsg = "인증번호를 정확히 입력해주세요 : ("
    setTime(result ? "일치" : "불일치")

    setErrMsg(
      <div style={{ fontSize: "12px", color: "#e95c63" }}>
        {result ? "" : errorMsg}
      </div>
    )
  } else {
    !result && (err.textContent = errorMsg)
  }

  // if (result) {
  //   if (flag) {
  //     setTime("일치")
  //     setErrMsg(<div />)
  //   }
  // } else {

  //   if (flag) {
  //     setTime("불일치")
  //     setErrMsg(
  //       <div style={{ fontSize: "12px", color: "#e95c63" }}>{msg} </div>
  //     )
  //   } else {
  //     err.textContent = msg
  //   }
  // }

  return result
}

// export let phone = {
//   number: "",
//   code: "",
//   timestamp: "",
//   check: false,
// }
export let phone_uuid = null
