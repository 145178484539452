import api from "apis"
import React from "react"
import Input from "components/tools/input.js"
import Title from "components/tools/InputTitle.js"
import { getDateYMD } from "utils"
import Password from "components/member/signUp/password.js"
import * as style from "./scroll.module.css"
import { navigate } from "gatsby"
import { vaild, onPwdCheck } from "utils/memberUtils"
const idmobile = async (setMain, setBtnName, phone_uuid) => {
  const [name] = document.getElementsByName("name")
  const [phoneNum] = document.getElementsByName("mobile")

  const result = await api.idmobile({
    body: {
      account_name: name.value,
      phone_uuid,
    },
  })

  if (!result || result.desc === msg.bad) alert("입력정보를 다시 확인해주세요")
  if (result.desc === msg.accErr) alert("없는 회원입니다")
  if (result.desc === msg.suc) {
    setMain(
      <>
        <div>
          <Input
            title={"이름"}
            tagname={"name"}
            read={true}
            value={name.value}
          />
        </div>
        <div>
          <Input
            title={"휴대전화"}
            tagname={"mobile"}
            read={true}
            value={phoneNum.value}
          />
        </div>
        <div>
          <Title name={"아이디"} />
          <div className={style.scroll}>
            {result.data.accounts.map((member, i) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  backgroundColor: "#FAFAFA",
                  height: "45px",
                  padding: "13.5px 7px 13.5px 7px",
                  color: "#7C7C7C",
                  marginBottom: "3px",
                  fontWeight: "500",
                }}
                key={i}
              >
                <div>{member.id}</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}
                >
                  {`${getDateYMD(
                    member.registerTime,
                    "YYYY년 MM월 DD일"
                  )} 가입`}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
    setBtnName("로그인")
  }
}

const idemail = async (setMain, setBtnName) => {
  const [name] = document.getElementsByName("name")
  const [email] = document.getElementsByName("email")
  const emailText = document.querySelector(".inputContainer > p")

  const result = await api.idemail({
    body: {
      account_name: name.value,
      account_email: email.value,
    },
  })

  console.log(result)
  console.log(result.desc)

  if (!result) alert("입력정보를 다시 확인해주세요")

  if (result.desc === msg.accErr) {
    emailText.style.display = "none"
    getErrMsgTag(email).innerHTML = `이메일 주소를 찾을 수 없어요.<br/>
			회원가입 시 등록한 이메일 주소를 입력해주세요.`
  } else if (result.desc === msg.suc) {
    emailText.style.display = "none"
    getErrMsgTag(email).innerHTML =
      "입력하신 메일 주소로 아이디를 보내드렸어요.<br/>메일을 확인해 보세요."
    setBtnName("로그인")
  }
  //  else {
  //   emailText.style.display = "block"
  //   getErrMsgTag(email).innerHTML = ""
  // }
}
const pwdmobile = async (setMain, setBtnName, phone_uuid) => {
  member.id = document.getElementsByName("id")[0]?.value
  member.name = document.getElementsByName("name")[0]?.value

  const result = await api.pwdmobileCheck({
    body: {
      account_id: member.id,
      account_name: member.name,
      phone_uuid,
    },
  })
  console.log(result.desc)

  const [idError, nameError, mobileError] =
    document.getElementsByClassName("errMsg")
  if (result.desc === msg.accErr) {
    idError.textContent = "아이디를 확인해주세요."
    nameError.textContent = ""
    mobileError.textContent = ""
  } else if (result.desc === msg.nameErr) {
    nameError.textContent = "이름을 확인해주세요."
    idError.textContent = ""
    mobileError.textContent = ""
  } else if (result.desc === msg.suc) {
    setMain(
      <>
        <Title name={"비밀번호 재설정"} />
        <Password />
      </>
    )
    setBtnName("저장하기")
  }
}

const pwdmobilesave = async (setMain, setBtnName, phone_uuid) => {
  const [, , pwd] = document.getElementsByName("pwd")

  onPwdCheck()

  if (!(!vaild.pwdMain || !vaild.pwdSub)) {
    const result = await api.pwdmobileReset({
      body: {
        account_id: member.id,
        account_name: member.name,
        account_password: pwd.value,
        phone_uuid,
      },
    })

    if (result.desc === msg.suc) {
      setMain(
        <>
          <Title name={"비밀번호가 변경되었습니다."} />
          <div style={{ color: "#7C7C7C", fontSize: "12px" }}>
            변경된 비밀번호로 로그인해주세요.
          </div>
        </>
      )

      setBtnName("로그인")
    }
  }
}

const pwdemail = async (setMain, setBtnName) => {
  const [name] = document.getElementsByName("name")
  const [email] = document.getElementsByName("email")
  const [id] = document.getElementsByName("id")
  const emailText = document.querySelector(".inputContainer > p")

  const result = await api.pwdemail({
    body: {
      account_id: id.value,
      account_name: name.value,
      account_email: email.value,
    },
  })

  console.log(result.desc)
  if (!result) alert("입력정보를 다시 확인해주세요")

  // if (result.desc === msg.accErr) {
  //   getErrMsgTag(name).style.display = "block"
  //   getErrMsgTag(name).innerHTML = `이름을 찾을 수 없습니다.<br/>
  //   회원가입 시 등록한 이름을 입력해주세요.`
  // } else {
  //   getErrMsgTag(name).style.display = "none"
  //   getErrMsgTag(name).innerHTML = ""
  // }

  if (
    result.desc === msg.accErr ||
    result.desc === msg.emailErr ||
    result.desc === msg.emailErr_2 ||
    result.desc === msg.nameErr
  ) {
    emailText.style.display = "none"

    getErrMsgTag(email).innerHTML = `이메일 주소를 찾을 수 없어요.<br/>
			회원가입 시 등록한 이메일 주소를 입력해주세요.`
  } else if (result.desc === msg.suc) {
    getErrMsgTag(email).innerHTML =
      "입력한 주소로 메일이 발송되었습니다.<br/> 회원님의 메일에서 임시 비밀번호를 확인하세요."
    setBtnName("로그인")
  } else {
    emailText.style.display = "block"
    getErrMsgTag(email).innerHTML = ""
  }
}

const find = {
  idmobile,
  idemail,
  pwdemail,
  pwdmobile,
  pwdmobilesave,
}

export default find

const msg = {
  suc: "success",
  accErr: "account not found",
  bad: "bad arguments",
  emailErr: `account invalid email`,
  emailErr_2: `"account_email" must be a valid email`,
  nameErr: `account invalid name`,
}

const getErrMsgTag = tag => {
  const errTag = tag.closest("div").querySelector(".errMsg")
  errTag.style.fontWeight = "500"
  return errTag
}

let member = {
  id: "",
  name: "",
}
