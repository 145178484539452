import React, { useCallback, useState, useEffect } from "react"
import * as styles from "./password.module.css"
import sucLock from "images/sucLock.png"
import errLock from "images/errLock.png"
import { pwdMainCheckUtil, pwdSubCheckUtil } from "utils/memberUtils"

const Password = props => {
  // const [pwdTag, pwdMsgTag, pwdCheckTag, pwdCheckMsgTag] =
  //   document.getElementsByName("pwd")

  // const [active, setActive] = useState(null)
  // const [subActive, setsubActive] = useState(null)

  // const pwdMainCheck = useCallback(e => {
  //   pwdMainCheckUtil(e, setActive)
  // }, [])

  // const pwdSubCheck = useCallback(e => {
  //   pwdSubCheckUtil(e, setsubActive)
  // }, [])

  // useEffect(() => {
  //   if (props.setPassword) {
  //     active && subActive ? props.setPassword(active && subActive) : ""
  //   }
  // }, [active, subActive])

  return (
    <div className={`${styles.pwdGap} inputContainer`}>
      <input
        type="password"
        placeholder="영문, 숫자, 특수문자를 조합한 8~16자리"
        // onChange={pwdMainCheck}
        style={props.inputStyle}
        name="pwd"
      />
      <div>
        <div className="errMsg" name="pwd"></div>
      </div>
      {/* 비밀번호 확인 */}
      <input
        type="password"
        placeholder="비밀번호 확인"
        name="pwd"
        // onChange={pwdSubCheck}
        style={props.inputStyle}
      />
      <div>
        <div className="errMsg" name="pwd"></div>
      </div>
      {/* {active !== null ? (
        <div className={styles.lock}>
          <img src={active ? sucLock : errLock} alt="sucLock" name="lockImg" />
        </div>
      ) : null} */}

      <div className={styles.checkMsg}>
        <div
          name="tag"
          style={{
            display: "none",
          }}
        >
          일치
        </div>
      </div>
    </div>
  )
}

export default Password
